export const genres = {
    Pod: true,
    Container: true,
    Steps: true,
    DAG: true,
    Retry: true,
    Skipped: true,
    Suspend: true,
    TaskGroup: false,
    StepGroup: false,
    Collapsed: true
};
